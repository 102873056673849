<template>
  <el-input
    ref="nameInput"
    clearable
    :type="inputType"
    :style="{ width: width }"
    :maxlength="word_limit_length"
    show-word-limit
    :placeholder="placeholder"
    v-model.trim="inputVal"
    @blur="inputChange('name')"
    @keyup.enter.native="$event.target.blur"
  />
</template>

<script>
export default {
  name: 'editInput',
  props: {
    name: '',
    width: '',
    placeholder: '',
    word_limit_length: {
      type: Number,
      default: 64,
    },
    inputType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      inputVal: '',
    }
  },
  watch: {
    name(val) {
      this.inputVal = val
    },
  },
  mounted() {
    this.inputVal = this.name
  },
  methods: {
    inputChange() {
      this.$emit('inputBlur', this.inputVal)
    },
  },
}
</script>

<style lang="scss" scoped></style>
